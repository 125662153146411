import { isDate } from "./functions";
import { DATA_NEEDED_TYPE } from "../constants/DataNeededTypes";
import moment from "moment";
import { logError } from "./logsFunctions";
import { getTranslation } from "./transaltion";
import { Col, Row } from "antd";
import { abbreviateNumber } from "js-abbreviation-number";
import { TbRuler } from "react-icons/tb";
import { PREDEFINED_TYPES } from "../constants/predefinedTypes";

export const displayContextRisk = (
  record: any,
  formatNumber: string,
  formatDate: string,
  currency: string,
  policyData?: any
) => {
  return (
    <span>
      <Row gutter={2}>
        {Object.entries(record?.context || {})?.map(
          ([attribute, value], index) => {
            const title = getTranslation(attribute, "data");
            return (
              <Col key={index} span={12} style={{ paddingInline: "20px" }}>
                <div
                  key={attribute}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>{title}: </span>
                  <span style={{ color: "#EE2737" }}>
                    {formatValueBasedOnTypeRisk(
                      record?.context[attribute],
                      attribute,
                      formatNumber,
                      formatDate,
                      currency,
                      policyData
                    )}
                  </span>
                </div>
              </Col>
            );
          }
        )}
      </Row>
    </span>
  );
};

export const getAttributesForLabel = (
  key: string,
  dataNeededAttributes: any,
  orgActionSettings: any,
  confsActionSettings: any,
  policyData?: any
): any => {
  const DataNeededFormattedToList = formatDataNeededFromURLToList(
    dataNeededAttributes?.dataNeeded
  );

  const descriptor: any = {};
  // policyData = dataNeeded for risk
  if (policyData) {
    const type = policyData[key]?.TYPE;
    if (type) {
      descriptor.type = type;
    }
    return descriptor;
  }
  const dataType = DataNeededFormattedToList
    ? DataNeededFormattedToList[key]
    : undefined;
  if (dataType) {
    // Check if any of the types defined in DATA_NEEDED_TYPE are present in dataType
    const dataTypePrefix = Object.values(DATA_NEEDED_TYPE).find((type) =>
      dataType.includes(type)
    );

    if (dataTypePrefix) {
      const suffix = dataType.substring(dataTypePrefix.length + 1); // Extracting the suffix
      // get the default value of settings from confs if exists
      // if it does not exist then from org if  not existe then from predefined action
      //*********** reconstruct action settings with all the right default values */
      // dataNeededAttributes?.actionParameters = predefined action settings
      //
      const mergedSettings: any = { ...dataNeededAttributes?.actionParameters };
      // Iterate through keys in PredefinedActionSetting
      Object.keys(mergedSettings).forEach((key) => {
        // Check if the key exists in record
        if (confsActionSettings && confsActionSettings[key]) {
          mergedSettings[key] = {
            name: mergedSettings[key].name,
            type: mergedSettings[key].type,
            defaultValue: confsActionSettings[key].defaultValue,
          };
        } else if (orgActionSettings && orgActionSettings[key]) {
          // If the key doesn't exist in record, check if it exists in orgSetting
          mergedSettings[key] = {
            name: orgActionSettings[key].name,
            type: orgActionSettings[key].type,
            defaultValue: orgActionSettings[key].defaultValue,
          };
        }
      });
      // retrieve the unit from action settings with the suffix
      const suffixActionSettings = mergedSettings[suffix];
      if (suffixActionSettings) {
        descriptor.unit = getTranslation(
          suffixActionSettings?.defaultValue,
          "data"
        );
      }
      descriptor.type = dataTypePrefix;
      // Assigning suffix to unit
    } else {
      descriptor.type = dataType; // If no type found, the whole dataType is type
    }
  }
  return descriptor;
};
const isISODateValid = (dateString: string): boolean => {
  // Attempt to create a Date object from the ISO date string
  const date = moment(dateString);
  // Check if the Date object is valid
  // A valid Date object is not equal to NaN when converted to a number
  return !isNaN(Number(date));
};
type AnyRecord = {
  [key: string]: any;
};
export const formatValueBasedOnType = (
  value: any,
  key: string,
  formatNumber: string,
  formatDate: string,
  currency: string,
  dataNeededAttributes: any,
  orgActionSettings: any,
  confsActionSettings: any,
  policyData?: any
) => {
  //policyData from  predefined

  const descriptor = getAttributesForLabel(
    key,
    dataNeededAttributes,
    orgActionSettings,
    confsActionSettings,
    policyData
  );
  if (descriptor && Object.keys(descriptor).length !== 0) {
    if (descriptor.type === DATA_NEEDED_TYPE.NUMBER) {
      const formattedValue = FormatCurrency(
        value as number,
        formatNumber,
        descriptor?.unit,
        null,
        2,
        2
      );
      return formattedValue;
    } else if (descriptor.type === DATA_NEEDED_TYPE.DATE) {
      const formattedDate = moment(value).format(formatDate || "DD/MM/YYYY");
      return formattedDate;
    } else if (descriptor.type === DATA_NEEDED_TYPE.AMOUNT) {
      const formattedAmount = FormatCurrency(
        value as number,
        formatNumber,
        null,
        currency,
        2,
        2
      );
      return formattedAmount;
    } else {
      return value;
    }
  } else {
    if (isDate(value)) {
      const formattedDate = moment(value).format(formatDate || "DD/MM/YYYY");
      return formattedDate;
    }

    if (typeof value == "number") {
      const formattedValue = FormatCurrency(
        value as number,
        formatNumber,
        descriptor?.unit,
        null,
        2,
        2
      );
      return formattedValue;
    }
    return value;
  }
};
export const changeFormatForListAttribute = (
  inputList: AnyRecord[],
  formatNumber: string,
  currency: string,
  formatDate: string,
  dataNeededAttributes: any,
  orgActionSettings: any,
  confsActionSettings: any
): void => {
  inputList.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        obj[key] = formatValueBasedOnType(
          obj[key],
          key,
          formatNumber,
          formatDate,
          currency,
          dataNeededAttributes,
          orgActionSettings,
          confsActionSettings
        );
      }
    });
  });
};
export const FormatCurrency = (
  value: any,
  language: string,
  unit: string | null,
  currency: string | null,
  minimumFractionDigits: number,
  maximumFractionDigits: number
): string => {
  // Handle cases where the value is 0 or not a number
  if (value === 0) {
    return unit ? `0 ${unit}` : "0";
  } else if (isNaN(value)) {
    return "Invalid number";
  }

  const options: Intl.NumberFormatOptions = {
    style: currency ? "currency" : "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    currencyDisplay: "symbol",
    ...(currency && { currency }),
  };

  const formattedValue = Intl.NumberFormat(
    currency ? currency : language,
    options
  ).format(value);

  return unit ? `${formattedValue} ${unit}` : formattedValue;
};

export const checkTypeAttribute = (
  chart: string,
  value: any,
  key: string,
  isLog: boolean,
  descriptorList: any
) => {
  if (descriptorList?.hasOwnProperty(key)) {
    const descriptor = descriptorList[key];

    if (descriptor) {
      const error = new Error(
        ` error in ${chart} ,The value of this attribute : ${key} does not match  the data type in Data needed : ${descriptor?.type} , Value given = ${value}`
      );

      switch (descriptor?.type) {
        case DATA_NEEDED_TYPE.STRING:
          if (typeof value === "string") {
            return true;
          } else {
            // here the type is invalid

            if (isLog) {
              logError(error, {
                module: "Runnings component",
                component: "Open actions",
              });
            }
            return false;
          }

        case DATA_NEEDED_TYPE.BOOLEAN:
          if (typeof value === "boolean") {
            return true;
          } else {
            if (isLog) {
              logError(error, {
                module: "Runnings component",
                component: "Open actions",
              });
            }
            return false;
          }

        case DATA_NEEDED_TYPE.DATE:
          const parsedDate = isISODateValid(value);
          if (parsedDate) {
            return true;
          } else {
            if (isLog) {
              logError(error, {
                module: "Runnings component",
                component: "Open actions",
              });
            }
            return false;
          }

        case "NUMBER":
          if (typeof value === "number") {
            return true;
          } else {
            if (isLog) {
              logError(error, {
                module: "Runnings component",
                component: "Open actions",
              });
            }
            return false;
          }

        case DATA_NEEDED_TYPE.AMOUNT:
          if (typeof value !== "number") {
            if (isLog) {
              logError(error, {
                module: "Runnings component",
                component: "Open actions",
              });
            }
            return false;
          }
          return true;

        // Additional validation for 'NUMBER_AMOUNT' type
        // Validate that the unit is a valid currency code
        // if (descriptor.unit && Currencies.hasOwnProperty(descriptor.unit)) {
        //   return true;
        // } else {
        //   // No unit specified or invalid unit, consider it's invalid
        //   const error = new Error(
        //     ` error in ${chart} , this field's unit : ${key} is missing   `
        //   );
        //   if (isLog) {
        //     logError(error, {
        //       module: "Runnings component",
        //       component: "Open actions",
        //     });
        //   }

        //   return true;
        // }
        default:
          return true; // Invalid type
      }
    } else {
      if (isLog) {
        const error = new Error(`No data type found for attributes: ${key}`);
        logError(error, {
          module: "Runnings component",
          component: "Open actions",
        });
      }
      return value;
    }
  } else {
    if (isLog) {
      const error = new Error(`No data type found for attributes: ${key}`);
      logError(error, {
        module: "Runnings component",
        component: "Open actions",
      });
    }
    return value;
  }
};
export const transformAttributesInputChartToCheckableList = (
  attributes: any[]
): any[] => {
  return attributes.map((attribute) => ({
    [attribute.AttributeName]: attribute.value,
  }));
};

export const checkTypeListAttribute = (
  chart: string,
  inputList: AnyRecord[],
  isLog: boolean,
  isDelete: boolean,
  descriptorList: any
) => {
  const outputList = JSON.parse(JSON.stringify(inputList));
  // is log  true= when the type is invalid write it in the logs
  // isDelete true= if type invalid remove the value from the list
  inputList?.forEach((obj, index) => {
    let shouldDelete = false;
    Object.keys(obj)?.forEach((key) => {
      if (key) {
        if (obj[key]) {
          const isValid = checkTypeAttribute(
            chart,
            obj[key],
            key,
            isLog,
            descriptorList
          );
          if (isDelete && isValid === false) {
            shouldDelete = true;
          }
        }
      }
    });
    if (shouldDelete) {
      // Remove the whole object
      outputList.splice(index, 1);
    }
  });
  return outputList;
};

export const formatDataNeededFromURLToList = (dataNeeded: any): any => {
  const result: { [key: string]: any } = {};
  const encounteredDataNames: Set<string> = new Set();
  if (
    dataNeeded &&
    dataNeeded?.hasOwnProperty("INPUTS") &&
    dataNeeded?.hasOwnProperty("OUTPUTS")
  ) {
    // Helper function to check for redundancy and add attribute and value to the result
    const addAttribute = (
      dataName: string,
      dataType: string,
      origin: string
    ) => {
      // if (!encounteredDataNames.has(dataName)) {
      result[dataName + "." + origin] = { type: dataType };
      // encounteredDataNames.add(dataName);
      // }
    };

    const traverse = (data: any, parentKey = "") => {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const obj = data[key];

          if (obj.TYPE === "ARRAY" || obj.TYPE === "OBJECT") {
            for (const subKey in obj) {
              if (subKey !== "TYPE") {
                addAttribute(`${subKey}`, obj[subKey]?.TYPE, key);
              }
            }
          }
          addAttribute(key, obj?.TYPE, key);
        }
      }
    };

    for (const type of ["INPUTS", "OUTPUTS"]) {
      traverse(dataNeeded[type], type);
    }
    return result;
  } else {
    return dataNeeded;
  }
};
export const formatDataNeededFromURLToListUnique = (dataNeeded: any): any => {
  const result: { [key: string]: any } = {};
  const encounteredDataNames: Set<string> = new Set();
  if (
    dataNeeded &&
    dataNeeded?.hasOwnProperty("INPUTS") &&
    dataNeeded?.hasOwnProperty("OUTPUTS")
  ) {
    // Helper function to check for redundancy and add attribute and value to the result
    const addAttribute = (dataName: string, dataType: string) => {
      if (!encounteredDataNames.has(dataName)) {
        result[dataName] = { type: dataType };
        encounteredDataNames.add(dataName);
      }
    };

    const traverse = (data: any, parentKey = "") => {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const obj = data[key];

          if (obj.TYPE === "ARRAY" || obj.TYPE === "OBJECT") {
            for (const subKey in obj) {
              if (subKey !== "TYPE") {
                addAttribute(`${subKey}`, obj[subKey]?.TYPE);
              }
            }
          }
          addAttribute(key, obj?.TYPE);
        }
      }
    };

    for (const type of ["INPUTS", "OUTPUTS"]) {
      traverse(dataNeeded[type], type);
    }
    return result;
  } else {
    return dataNeeded;
  }
};
export const formatValueBasedOnTypeRisk = (
  value: any,
  key: string,
  formatNumber: string,
  formatDate: string,
  currency: string,
  policyData: any
) => {
  //policyData from  predefined risk
  if (policyData) {
    const attributeType = policyData[key]?.TYPE;

    if (attributeType) {
      if (attributeType === DATA_NEEDED_TYPE.NUMBER) {
        const formattedValue = FormatCurrency(
          value as number,
          formatNumber,
          null,
          null,
          2,
          2
        );
        return formattedValue;
      } else if (
        attributeType === DATA_NEEDED_TYPE.DATE ||
        attributeType === DATA_NEEDED_TYPE.DATETIME
      ) {
        const formattedDate = moment(value).format(formatDate || "DD/MM/YYYY");
        return formattedDate;
      } else if (attributeType === DATA_NEEDED_TYPE.AMOUNT) {
        const formattedAmount = FormatCurrency(
          value as number,
          formatNumber,
          null,
          currency,
          2,
          2
        );
        return formattedAmount;
      } else {
        return value;
      }
    } else {
      if (isDate(value)) {
        const formattedDate = moment(value).format(formatDate || "DD/MM/YYYY");
        return formattedDate;
      }

      if (typeof value == "number") {
        const formattedValue = FormatCurrency(
          value as number,
          formatNumber,
          null,
          null,
          2,
          2
        );
        return formattedValue;
      }
    }

    return value;
  }
};

export const getCurrentActionSettings = (
  actionSettings: any,
  orgActionSettings: any,
  confsActionSettings: any,
  setCurrentActionSettings: React.Dispatch<React.SetStateAction<any>>
) => {
  const result: any = {};

  for (const key in actionSettings) {
    if (confsActionSettings?.hasOwnProperty(key)) {
      result[key] = { defaultValue: confsActionSettings[key]?.defaultValue };
    } else if (orgActionSettings?.hasOwnProperty(key)) {
      result[key] = { defaultValue: orgActionSettings[key]?.defaultValue };
    }
  }

  setCurrentActionSettings(result);
  return result;
};

export const getDescriptorsForDataNeededFields = (
  DataNeededFormattedToList: any,
  ActionSettings: any
): any => {
  const descriptors: any = {};
  if (DataNeededFormattedToList) {
    Object.keys(DataNeededFormattedToList)?.forEach((key) => {
      const descriptor: any = {};
      const dataType = DataNeededFormattedToList[key]?.type;

      if (dataType) {
        // Check if any of the types defined in DATA_NEEDED_TYPE are present in dataType
        const dataTypePrefix = Object.values(DATA_NEEDED_TYPE)?.find((type) =>
          dataType?.includes(type)
        );

        if (dataTypePrefix) {
          const suffix = dataType?.substring(dataTypePrefix.length + 1); // Extracting the suffix

          // Retrieve the unit from action settings with the suffix
          const suffixActionSettings = ActionSettings[suffix];
          if (suffixActionSettings) {
            descriptor.unit = getTranslation(
              suffixActionSettings?.defaultValue,
              "data"
            );
          }
          descriptor.type = dataTypePrefix;
        } else {
          descriptor.type = dataType; // If no type found, the whole dataType is type
        }
      }

      descriptors[key] = descriptor;
    });
  }

  return descriptors;
};

export const getFormatedValue = (
  value: any,
  key: string,
  orgaSettings: any,
  descriptorList: any,
  translation?: boolean
) => {
  if (descriptorList?.hasOwnProperty(key)) {
    const descriptor = descriptorList[key];

    if (descriptor && Object.keys(descriptor).length !== 0) {
      if (descriptor.type === DATA_NEEDED_TYPE.NUMBER) {
        const formattedValue = FormatCurrency(
          value as number,
          orgaSettings?.formatNumber,
          descriptor?.unit,
          null,
          2,
          2
        );
        return formattedValue;
      } else if (
        descriptor.type === DATA_NEEDED_TYPE.DATE ||
        descriptor.type === DATA_NEEDED_TYPE.DATETIME
      ) {
        const formattedDate = moment(value).format(
          orgaSettings?.formatDate || "DD/MM/YYYY"
        );

        return formattedDate;
      } else if (descriptor.type === DATA_NEEDED_TYPE.AMOUNT) {
        const formattedAmount = FormatCurrency(
          value as number,
          orgaSettings?.formatNumber,
          null,
          orgaSettings?.currency,
          2,
          2
        );
        return formattedAmount;
      } else {
        return translation === false && translation !== null
          ? value
          : getTranslation(value, "data");
      }
    }
  } else {
    if (isDate(value)) {
      const formattedDate = moment(value).format(
        orgaSettings?.formatDate || "DD/MM/YYYY"
      );
      return formattedDate;
    }

    if (typeof value == "number") {
      const formattedValue = FormatCurrency(
        value as number,
        orgaSettings?.formatNumber,
        "",
        null,
        2,
        2
      );
      return formattedValue;
    }
  }
  return translation === false && translation !== null ? value : value;
};

export const formatNumberInK = (
  value: any,
  key: string,
  formatNumber: string,
  currency: string,
  descriptorList: any
): string => {
  if (descriptorList?.hasOwnProperty(key)) {
    const descriptor = descriptorList[key];
    let currentSymbol: any = "";
    if (descriptor) {
      if (descriptor.type === DATA_NEEDED_TYPE.AMOUNT) {
        const formatter = new Intl.NumberFormat(formatNumber, {
          style: "currency",
          currency: currency,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        currentSymbol =
          formatter.formatToParts(1).find((part) => part.type === "currency")
            ?.value || "";
      } else {
        if (descriptor.unit) {
          currentSymbol = descriptor.unit;
        }
      }
    }
    //abreviate number to  thousand: "K",million: "M",billion: "B",trillion: "T"
    let abbreviatedValue = abbreviateNumber(value, 1);
    // add currency symbole
    abbreviatedValue += currentSymbol;

    return `${abbreviatedValue}`;
  }
  return `${value}`;
};

export const isValidPredefinedType = (typecomponent: string): boolean => {
  return Object.values(PREDEFINED_TYPES).includes(typecomponent);
};

type InputObjectSettings = {
  [key: string]: {
    choice?: any;
    name?: any;
    defaultValue?: any;
  };
};

export const isFormatSettingsValid = (obj: InputObjectSettings): boolean => {
  const isFormatValid = Object.values(obj || {})?.every(
    (item) =>
      item.hasOwnProperty("choice") &&
      item.hasOwnProperty("name") &&
      item.hasOwnProperty("defaultValue")
  );
  if (!isFormatValid) {
    const error = new Error(` Error , settings format   is not valid `);
    logError(error, {
      module: "Predefined Action",
      component: "Predefined action settings ",
    });
  }
  return isFormatValid;
};

type InputObjectPolicyData = {
  [key: string]: {
    TYPE: any;
    POSITION?: any;
  };
};

export const isFormatPolicyDataValid = (
  obj: InputObjectPolicyData
): boolean => {
  const isFormatValid = Object.values(obj || {})?.every((item) => {
    // Check if "TYPE" exists and if "POSITION" is present, it's spelled correctly
    return (
      item.hasOwnProperty("TYPE") &&
      (!item.hasOwnProperty("POSITION") || item.hasOwnProperty("POSITION"))
    );
  });

  if (!isFormatValid) {
    const error = new Error(` Error , policyData format is not valid `);
    logError(error, {
      module: "Predefined Risk",
      component: "Policy Data",
    });
  }
  return isFormatValid;
};

export function getLastJsonPathSegment(jsonPath: string): string {
  const segments = jsonPath.split(".");
  return segments[segments.length - 1];
}
