import i18n from "../parameters/i18n";

export const createKey = (label: string) => {
  // Trim spaces from the beginning and end
  const trimmedString = label?.trim();

  // Replace spaces with underscores
  const stringWithUnderscores = trimmedString?.replace(/\s/g, "_");

  // Convert the string to uppercase
  const uppercaseString = stringWithUnderscores?.toUpperCase();
  //console.log(uppercaseString);
  return uppercaseString;
};

export const getTranslation = (key: string, file: string): string => {
  if (key && typeof key === "string") {
    const translatedValue = i18n.t(`${file}:${key}`, {
      defaultValue:
        key
          ?.toLowerCase()
          ?.replace(/_/g, " ")
          ?.replace(/(?:^|\s)\S/g, (a) => a?.toUpperCase()) || key,
    });

    return translatedValue;
  } else {
    return "";
  }
};
